import { ROUTES as CORE_ROUTES} from 'core/utils/constants';

export const pathPrefix = '/b';

export const ROUTES = {
  ...CORE_ROUTES,
  BUSSESSES: '/properties/',
  CREATE_BUSSESSE: `${pathPrefix}/create/`,
  HOME: `${pathPrefix}/:businessId/`,
  PAYMENT_MODES: '/my-account/payment-modes/',
  BOOKINGS: `${pathPrefix}/:businessId/bookings/`,
  CHECK_IN: `${pathPrefix}/:businessId/checkin/`,
  CHECKOUT: `${pathPrefix}/:businessId/checkout/`,
  CHECKOUT_NOTICES: `${pathPrefix}/:businessId/checkout/notices`,
  AVAILABILITY: `${pathPrefix}/:businessId/availability/`,
  INMATES: `${pathPrefix}/:businessId/inmates/`,
  PAYMENTS: `${pathPrefix}/:businessId/payments/`,
  BUSSESSES_INFO: `${pathPrefix}/:businessId/info/`,
  BUY_BUSINESS_LICENSE: `${pathPrefix}/:businessId/license/`,
  MANAGE_STAFF: `${pathPrefix}/:businessId/manager-staff`,
  MANAGE_PG_RESOURCES: `${pathPrefix}/:businessId/manager-resources`,
  PAYMENT_APPROVALS: `${pathPrefix}/:businessId/payments/approvals`,
  ISSUES: `${pathPrefix}/:businessId/issues`,
  EXPENSES: `${pathPrefix}/:businessId/expenses`,
  REPORTS: `${pathPrefix}/:businessId/reports`,
  PAYMENTS_REPORT: `${pathPrefix}/:businessId/reports/payments`,
  CHECKOUT_REPORT: `${pathPrefix}/:businessId/reports/checkout`,
  EXPENSE_REPORT: `${pathPrefix}/:businessId/reports/expense`,
  FOOD_REPORT: `${pathPrefix}/:businessId/reports/food`,
  BOOKING_REPORT: `${pathPrefix}/:businessId/reports/booking`,
  PAYOUTS_REPORT: `${pathPrefix}/:businessId/reports/payout`,
  SMS_REPORT: `${pathPrefix}/:businessId/reports/sms`,
  TAX_REPORT: `${pathPrefix}/:businessId/reports/tax`,

  PERFORMANCE: `${pathPrefix}/:businessId/performance/`,
  COMPLETE_CREATE_BUSINESS: `${pathPrefix}/:businessId/complete/s/:stage/`,
  BOOKING_GATEWAY_CHARGES: '/booking/gateway-charges/',
  BOOKING_GATEWAY_CHARGES_EXAMPLE: '/booking/gateway-charges/explained/',
  SUPPORT: '/support/',
}
