import React, { memo, useState } from 'react';  
import {  Button, TextField, Link, Box, Grid, Typography, CircularProgress, Divider, InputAdornment } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { signInUser } from 'core/services/auth';
import { useNavigate, Link as RouteLink } from "react-router-dom";
import { useSnackbar } from 'notistack';
// import { AuthContext } from 'core/context';
import LoginIcon from '@mui/icons-material/Login';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ROUTES } from 'core/utils/constants';
import { logger } from 'core/utils/logger';

const SignIn = () => {
  logger.info('SignIn section mounting...');
  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  // const authContext = useContext(AuthContext);
  const [isSiningIn, setSiningIn] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      mobile: '',
      password:''
    }
  });

  const onSubmit = (body) => {
    logger.debug('SignIn sending data...');
    setSiningIn(true);
    signInUser({body}).then(({response={}, error}) => {
      if(error) {
        setSiningIn(false);
        enqueueSnackbar(error, {variant: 'error'});
        return;
      }
      // const { statusCode, data, message} = response;
      setSiningIn(false);
      navigate('/');

      // if(statusCode){
      //   authContext.updateAuthContext({isUserLoggedIn: true, ...data});
      //   // setTimeout(()=>navigate('/'), 100);
      //   navigate('/');
      // } else {
      //   authContext.updateAuthContext({isUserLoggedIn: false, accessToken: null});
      //   enqueueSnackbar(message, {variant: 'error'});
      // }
    });
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{px: {xs: 0, sm: 2, md: 4}}}>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" >
        <Typography component="h1" variant="h6" color="primary" textAlign="center" sx={{mb: 1, fontWeight: 500}}>
          Login to your account
        </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Controller
              control={control}
              name="mobile"
              id="mobile"
              autoComplete="mobile"
              rules={{ required: "Enter your mobile number",
                pattern: {
                  value: /^[0-9]{10}$/i,
                  message: "Enter a valid 10 digit mobile number",
                },
                // maxLength: 10,
              }}
              render={({field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  label="Mobile Number"
                  error={errors.mobile}
                  helperText={errors.mobile?.message}
                  // maxLength={10}
                  placeholder="Mobile Number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Controller
              control={control}
              name="password"
              id="password"
              rules={{ required: "Enter your password" }}
              render={({field}) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  label="Password"
                  autoComplete="current-password"
                  type={showPassword ? 'text': 'password'}
                  error={errors.password?.type === 'required'}
                  helperText={errors.password?.message}
                  placeholder="Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {showPassword ? <VisibilityOffIcon sx={{cursor: 'pointer'}} onClick={() => setShowPassword(false)} /> 
                          : <VisibilityIcon sx={{cursor: 'pointer'}} onClick={() => setShowPassword(true)} /> }
                        
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid container justifyContent="right" sx={{mt: 1 }} >
            <Grid item >
              <Link component={RouteLink} to={ROUTES.FORGOT_PASSWORD} color="tertiary.main" sx={{textDecoration:'none'}}>
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" >
            <Button
              disabled={isSiningIn}
              color="primary"
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, px: 3}}
              endIcon={isSiningIn ? <CircularProgress size={20} /> : <LoginIcon /> }
              size="large"
              >
              {isSiningIn ? 'Signing In': 'Login'}
            </Button>
            <Grid container justifyContent="center" sx={{mt: 2}} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider>
                  <Typography variant='body1'  >
                    New here?
                  </Typography>
                </Divider>
                <Typography variant='body1' display="block"  color="secondary" sx={{my: 3}}>
                Register to discover great features!
              </Typography>
              <Typography variant='body1' display="block"  sx={{my: 3}}>
                <Button variant='outlined'  component={RouteLink} to={ROUTES.REGISTER} >Create Account</Button>
              </Typography>
              </Grid>
            
            </Grid>
          </Grid>
      </Grid>
    </Box>
  );
}
 
export default memo(SignIn);
