import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES, pathPrefix } from './constants';
import { BlankPage, BlankWithPageTitle } from "core/layouts";
import HomePageRedirect from "HomePageRedirect";
// import { BlankWithPageTitleV1 } from "core/layouts/pages/BlankWithPageTitleV1";
// import RoutePage from "pages/RoutePage";

// Lazy imports 
const BusinessesPage = lazy(() => import('pages/businesses'));
const CreateBusinessCenter = lazy(() => import('pages/addBusinessCenter'));
const HomePage = lazy(() => import('pages/dashboard'));
const BusinessInfoPage = lazy(() => import('pages/businessInfo'));
const ManageStaffPage = lazy(() => import('pages/manage-staff'));
const ManagePGResources = lazy(() => import('pages/manage-pg-resources'));
const BookingsPage = lazy(() => import('pages/bookings'));
const CheckInPage = lazy(() => import('pages/checkin'));
const BedAvailabilityPage = lazy(() => import('pages/availability'));
const InmatesPage = lazy(() => import('pages/inmates'));
const PaymentsPage = lazy(() => import('pages/payments'));
const BusinessLicensePage = lazy(() => import('pages/businessLicense'));
const PaymentApprovalsPage = lazy(() => import('pages/paymentApprovals'));
const InmateCheckoutPage = lazy(() => import('pages/inmateCheckout'));
const CheckoutNoticesPage = lazy(() => import('pages/checkoutNotices'));
const IssuesPage = lazy(() => import('pages/issues'));
const ExpensesPage = lazy(() => import('pages/expenses'));
const ReportsPage = lazy(() => import('pages/reports'));
const CheckoutReportPage = lazy(() => import('pages/reports/CheckoutReport'));
const PaymentsReportPage = lazy(() => import('pages/reports/PaymentsReport'));
const ExpenseReportPage = lazy(() => import('pages/reports/ExpenseReport'));
const SMSReportPage = lazy(() => import('pages/reports/SMSReport'));
const FoodReportPage = lazy(() => import('pages/reports/FoodReport'));
const BookingReportPage = lazy(() => import('pages/reports/BookingReport'));
const PayoutReportPage = lazy(() => import('pages/reports/PayoutReport'));
const TaxReportPage = lazy(() => import('pages/reports/TaxReport'));
const PerformancePage = lazy(() => import('pages/performance'));
const HelpAndSupport = lazy(() => import('pages/support'));

// const CompleteBusinessProfile = lazy(() => import('pages/addBusinessCenter/completeProfile'));
// const PaytmPaymentGatewayChargesPage = lazy(() => import('pages/paytm-gateway-fee'));

const AppRoutes = [
  {
    path: "/",
    exact: true,
    hideInBreadcrumb: true,
    pageLayout: BlankPage,
    component: () => <Navigate to={pathPrefix} replace={true} />
  },
  {
    name: 'Home Redirect',
    path: pathPrefix,
    enableAuth: true,
    exact: true,
    hideInBreadcrumb: true,
    pageLayout: BlankPage,
    component: HomePageRedirect,
    pageLayoutProps: {
      hideBreadcrumb: true,
    }
  },
  {
    name: 'Businesses',
    path: ROUTES.BUSSESSES,
    pageLayout: BlankPage,
    component: BusinessesPage,
    enableAuth: true,
  },
  {
    name: 'CreateBusinessCenter',
    path: ROUTES.CREATE_BUSSESSE,
    pageLayout: BlankPage,
    component: CreateBusinessCenter,
    enableAuth: true,
    roles: [] //'Add PG'
  },
  {
    name: 'Home',
    path: ROUTES.HOME,
    pageLayout: BlankPage,
    component: HomePage,
    enableAuth: true,
    pageLayoutProps: {
      // title: 'Dashboard',
      // subTitle: 'stati, etc...',
    },
  },
  {
    name: 'Info',
    path: ROUTES.BUSSESSES_INFO,
    pageLayout: BlankPage,
    component: BusinessInfoPage,
    enableAuth: true,
    pageLayoutProps: {
      pageData: {
        info: {
          title: 'Complete your business profile',
          description: 'A business profile gives a general idea of what a business is about and a glimpse into the business services it provides.' 
        },
      }
    },
  },
  {
    name: 'ManagerStaff',
    path: ROUTES.MANAGE_STAFF,
    pageLayout: BlankWithPageTitle,
    component: ManageStaffPage,
    enableAuth: true,
    licenseRequired: true,
    roles: ['View staff'],
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Staff',
      subTitle: 'Add/Delete/Manage Partners, Managers, Staff of your property',
    },
  },
  {
    name: 'ManagePGResources',
    path: ROUTES.MANAGE_PG_RESOURCES,
    pageLayout: BlankWithPageTitle,
    component: ManagePGResources,
    licenseRequired: true,
    roles: ['View rooms'],
    enableAuth: true,
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Manage Resources',
      subTitle: 'Configure Block/Floor/Flat/Room/Bed of your property',
    },
  },
  {
    name: 'Bookings',
    path: ROUTES.BOOKINGS,
    pageLayout: BlankPage,
    licenseRequired: true,
    component: BookingsPage,
    enableAuth: true,
    roles: ['View bookings'],
    // licenseRequired: true,
    // verificationRequired: true,
    pageLayoutProps: {
      pageData: {
        // info: {
        //   title: 'Let customers make bookings',
        //   description: 'A simple and easy seat booking system allows your customers to pay online and make seat reservations.' 
        // }
      },
      // title: 'Bookings',
      // subTitle: 'stati, etc...',
    },
  },
  {
    name: 'CheckIn',
    path: ROUTES.CHECK_IN,
    pageLayout: BlankWithPageTitle,
    component: CheckInPage,
    licenseRequired: true,
    enableAuth: true,
    roles: ['Check-In'],
    // licenseRequired: true,
    // verificationRequired: true,
    pageLayoutProps: {
      hideBreadcrumb: true,
      pageBorder: false,
      title: 'Inmate Check-In',
      subTitle: 'Allocate a bed/room to your tenant.' 
      // pageData: {
      //   info: {
      //     title: 'Inmate Check In',
      //     description: 'A simple and easy seat booking system allows your customers to pay online and make seat reservations.' 
      //   }
      // },
    },
  },
  {
    name: 'BedAvailability',
    path: ROUTES.AVAILABILITY,
    pageLayout: BlankWithPageTitle,
    component: BedAvailabilityPage,
    roles: ['View availability'],
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      hideBreadcrumb: true,
      pageBorder: false,
      title: 'Bed Availability',
      subTitle: 'Check bed availability, Book, Check-In and take checkout notice for beds',
    },
  },
  {
    name: 'Inmates',
    path: ROUTES.INMATES,
    pageLayout: BlankWithPageTitle,
    component: InmatesPage,
    enableAuth: true,
    roles: ['View inmates'],
    // licenseRequired: true,
    // verificationRequired: true,
    pageLayoutProps: {
      hideBreadcrumb: true,
      pageBorder: false,
      title: 'Inmates',
      subTitle: 'Check/modify tenant details, collect payments, take checkout notice and checkout tenants'
      // pageData: {
      //   info: {
      //     title: 'Boost your revenue with offers',
      //     description: 'An effective way of promoting your business and increasing income. Let\'s create and share your offers.' 
      //   }
      // },
      // subTitle: 'stati, etc...',
    },
  },
  {
    name: 'Payments',
    path: ROUTES.PAYMENTS,
    pageLayout: BlankWithPageTitle,
    component: PaymentsPage,
    roles: ['View payments'],
    enableAuth: true,
    pageLayoutProps: {
      pageBorder: false,
      hideBreadcrumb: true,
      title: 'Inmate Payments',
      subTitle: 'Collect due payments, send reminders',
    },
  },
  {
    name: 'BuyBusinessLicense',
    path: ROUTES.BUY_BUSINESS_LICENSE,
    pageLayout: BlankPage,
    component: BusinessLicensePage,
    enableAuth: true,
    roles: ['Renew licence']
  },
  {
    name: 'PaymentApprovals',
    path: ROUTES.PAYMENT_APPROVALS,
    pageLayout: BlankWithPageTitle,
    roles: ['Approve payments'],
    component: PaymentApprovalsPage,
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      pageBorder: false,
      hideBreadcrumb: true,
      title: 'Payment Approvals',
      subTitle: 'Approve payments after verifying in your bank account.',
    }
  },
  {
    name: 'InmateCheckout',
    path: ROUTES.CHECKOUT,
    pageLayout: BlankWithPageTitle,
    component: InmateCheckoutPage,
    roles: ['Checkout'],
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      pageBorder: false,
      hideBreadcrumb: true,
      title: 'Inmate Checkout',
      subTitle: 'Collect/adjust final payments and vacate tenants from your facility',
    }
  },
  {
    name: 'InmateCheckoutNotices',
    path: ROUTES.CHECKOUT_NOTICES,
    pageLayout: BlankWithPageTitle,
    component: CheckoutNoticesPage,
    roles: ['View notice'],
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      pageBorder: false,
      hideBreadcrumb: true,
      title: 'Inmate Checkout Notices',
      subTitle: 'Checkout tenants from your facility, cancel notices',
    }
  },
  {
    name: 'Issues',
    path: ROUTES.ISSUES,
    pageLayout: BlankWithPageTitle,
    component: IssuesPage,
    roles: ['View issue'],
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      pageBorder: false,
      hideBreadcrumb: true,
      title: 'Issues',
      subTitle: 'Add/resolve problems related to your facility',
    }
  },
  {
    name: 'Expenses',
    path: ROUTES.EXPENSES,
    pageLayout: BlankWithPageTitle,
    component: ExpensesPage,
    roles: ['View expense'],
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      pageBorder: false,
      hideBreadcrumb: true,
      title: 'Expenses',
      subTitle: 'Add your property related spendings, manage recurrences',
    }
  },
  {
    name: 'Reports',
    path: ROUTES.REPORTS,
    pageLayout: BlankWithPageTitle,
    component: ReportsPage,
    roles: ['View reports', 'View Tax Info'],
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      title: 'Reports',
      subTitle: 'Check your payments, expenses, inmate details etc',
    }
  },
  {
    name: 'Checkout Report',
    path: ROUTES.CHECKOUT_REPORT,
    pageLayout: BlankWithPageTitle,
    component: CheckoutReportPage,
    roles: ['View reports'],
    enableAuth: true,
    pageLayoutProps: {
      title: 'Checkout Report',
    }
  },
  {
    name: 'Payments Report',
    path: ROUTES.PAYMENTS_REPORT,
    pageLayout: BlankWithPageTitle,
    component: PaymentsReportPage,
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      title: 'Payments Report',
    }
  },
  {
    name: 'Expense Report',
    path: ROUTES.EXPENSE_REPORT,
    pageLayout: BlankWithPageTitle,
    component: ExpenseReportPage,
    roles: ['View reports'],
    enableAuth: true,
    pageLayoutProps: {
      title: 'Expense Report',
    }
  },
  {
    name: 'Food Report',
    path: ROUTES.FOOD_REPORT,
    pageLayout: BlankWithPageTitle,
    component: FoodReportPage,
    roles: ['View reports'],
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      title: 'Food Report',
    }
  },
  {
    name: 'Booking Report',
    path: ROUTES.BOOKING_REPORT,
    pageLayout: BlankWithPageTitle,
    component: BookingReportPage,
    licenseRequired: true,
    roles: ['View reports'],
    enableAuth: true,
    pageLayoutProps: {
      title: 'Booking Report',
    }
  },
  {
    name: 'Payout Report',
    path: ROUTES.PAYOUTS_REPORT,
    pageLayout: BlankWithPageTitle,
    component: PayoutReportPage,
    enableAuth: true,
    pageLayoutProps: {
      title: 'Payout Report',
    }
  },
  {
    name: 'SMS Report',
    path: ROUTES.SMS_REPORT,
    pageLayout: BlankWithPageTitle,
    component: SMSReportPage,
    roles: ['View reports'],
    licenseRequired: true,
    enableAuth: true,
    pageLayoutProps: {
      title: 'SMS Report',
    }
  },
  {
    name: 'Tax Report',
    path: ROUTES.TAX_REPORT,
    pageLayout: BlankWithPageTitle,
    component: TaxReportPage,
    roles: ['View Tax Info'],
    enableAuth: true,
    pageLayoutProps: {
      pageBorder: false,
      title: 'Tax Report',
    }
  },
  {
    name: 'Performance',
    path: ROUTES.PERFORMANCE,
    pageLayout: BlankPage,
    component: PerformancePage,
    enableAuth: true,
    roles: ['View dashboard'],
    licenseRequired: true,
    // verificationRequired: true,
    pageLayoutProps: {
      // title: 'Insights',
      pageData: {
        info: {
          title: 'Explore your business',
          description: 'Get the insights you need to increase your customers. With simple analytics, see how to engage customers with your business.' 
        },
      }
      // subTitle: 'stati, etc...',
    },
  },
  
  // {
  //   name: 'CompletCreateBusiness',
  //   path: ROUTES.COMPLETE_CREATE_BUSINESS,
  //   pageLayout: BlankPage,
  //   component: CompleteBusinessProfile,
  //   enableAuth: true,
  // },

  // {
  //   name: 'Booking Gateway Fees',
  //   path: ROUTES.BOOKING_GATEWAY_CHARGES,
  //   pageLayout: BlankPublicPageWithHeader,
  //   component: PaytmPaymentGatewayChargesPage,
  // },
  // {
  //   name: 'Booking Gateway Fee Explained',
  //   path: ROUTES.BOOKING_GATEWAY_CHARGES_EXAMPLE,
  //   pageLayout: BlankPublicPageWithHeader,
  //   component: BookingChargesExamplePage,
  // },
  {
    name: 'Help and Support',
    path: ROUTES.SUPPORT,
    enableAuth: true,
    pageLayout: BlankWithPageTitle,
    component: HelpAndSupport,
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Contact Us',
      subTitle: 'Please fill out the form below and we will get in touch with you.',
    },
  },

];
export default AppRoutes;
