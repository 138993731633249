import { ROUTES } from './constants';

const NavbarMenuItems = [
  {
    name: 'Home',
    icon: 'home',
    url: ROUTES.HOME,
    // isBookmarked: true
    // licenseRequired: true
  },
  {
    name: 'Performance',
    icon: 'insights',
    url:  ROUTES.PERFORMANCE,
    roles: ['View dashboard'],
    licenseRequired: true
    // isBookmarked: true,
  },
  {
    name: 'Bookings',
    icon: 'event',
    roles: ['View bookings'],
    url: ROUTES.BOOKINGS,
    licenseRequired: true
  },
  {
    name: 'Check-In',
    icon: 'hail',
    roles: ['Check-In'],
    url: ROUTES.CHECK_IN,
    licenseRequired: true
  },
  {
    name: 'Availability',
    icon: 'bedroom_child',
    roles: ['View availability'],
    url: ROUTES.AVAILABILITY,
    licenseRequired: true
  },
  {
    name: 'Inmates',
    icon: 'group',
    roles: ['View inmates'],
    url: ROUTES.INMATES,
    licenseRequired: true
  },
  {
    name: 'Payments',
    icon: 'currency_rupee',
    roles: ['View payments'],
    url: ROUTES.PAYMENTS,
    licenseRequired: true
  },
  {
    name: 'Payment Approvals',
    icon: 'credit_score',
    roles: ['Approve payments'],
    url: ROUTES.PAYMENT_APPROVALS,
    licenseRequired: true
  },  
  {
    name: 'Checkout Notices',
    icon: 'assignment_late',
    roles: ['View notice'],
    url: ROUTES.CHECKOUT_NOTICES,
    licenseRequired: true
  },
  {
    name: 'Inmate Checkout',
    icon: 'directions_walk',
    roles: ['Checkout'],
    url: ROUTES.CHECKOUT,
    licenseRequired: true
  },
  {
    name: 'Issues',
    icon: 'error',
    roles: ['View issue'],
    url: ROUTES.ISSUES,
    licenseRequired: true
  },
  {
    name: 'Expenses',
    icon: 'calculate',
    roles: ['View expense'],
    url: ROUTES.EXPENSES,
    licenseRequired: true
  },
  {
    name: 'Reports',
    icon: 'description',
    url:  ROUTES.REPORTS,
    roles: ['View reports', 'View Tax Info'],
    licenseRequired: true
    // items: [
    //   {
    //     name: 'Booking',
    //     icon: 'date_range',
    //     url: '/reports/booking/'
    //   },
    //   {
    //     name: 'Incentive',
    //     icon: 'account_balance_wallet',
    //     url: '/reports/incentive/'
    //   },
    //   {
    //     name: 'Payout',
    //     icon: 'payments',
    //     url: '/reports/payout/'
    //   },
    //   {
    //     name: 'Enquiry',
    //     icon: 'forum',
    //     url: '/reports/enquiry/'
    //   },
    // ]
  },
  // {
  //   name: 'Operations',
  //   subHeading: true,
  // },
  // {
  //   name: 'Operations',
  //   icon: 'build_circle',
  //   group: true,
  //   roles: [],
  //   items: [
  //     {
  //       name: 'Revenue Sharing',
  //       icon: 'share',
  //       url: '/operations/revenue-sharing/'
  //     },
  //     {
  //       name: 'Dynamic Pricing',
  //       icon: 'toll',
  //       url: '/operations/dynamic-pricing/'
  //     },
  //   ]
  // },
  {
    divider: true,
  },
  {
    name: 'Info',
    icon: 'store',
    roles: [],
    url: ROUTES.BUSSESSES_INFO,
  },
  {
    name: 'Properties',
    icon: 'storefront',
    roles: [],
    url: ROUTES.BUSSESSES
  },
  {
    name: 'Help & Support',
    icon: 'support_agent',
    roles: [],
    url: '/support/'
  },
  // {
  //   name: 'Feedback',
  //   icon: 'rate_review',
  //   roles: [],
  //   url: '/feedback/'
  // },
  // {
  //   name: 'Users',
  //   icon: 'groups',
  //   group: true,
  //   roles: [],
  //   items: [
  //     {
  //       name: 'Search',
  //       icon: 'person_search',
  //       url: '/b/:businessId/user/search/',
  //     },
  //     {
  //       name: 'Add',
  //       icon: 'group_add',
  //       url: '/user/search/',
  //       isBookmarked: true
  //     },
  //   ]
  // },
  // {
  //   name: 'Manage PG',
  //   icon: 'maps_home_work',
  //   group: true,
  //   roles: ['admin', 'manager', 'employee'],
  //   items: [
  //     {
  //       name: 'Search',
  //       icon: 'manage_search',
  //       url: '/pg/search/'
  //     },
  //     {
  //       name: 'Add',
  //       icon: 'house',
  //       url: '/pg/add/'
  //     },
  //   ]
  // },
];

export default NavbarMenuItems;
